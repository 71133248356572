// ** React Imports
import { lazy } from 'react'

const ProductsList = lazy(() => import('views/brands/products/list'))
const ProductsAdd = lazy(() => import('views/brands/products/add'))
const ProductsView = lazy(() => import('views/brands/products/view'))
const ProductsEdit = lazy(() => import('views/brands/products/edit'))

const LegalsList = lazy(() => import('views/brands/legals/list'))
const LegalsAdd = lazy(() => import('views/brands/legals/add'))
const LegalsView = lazy(() => import('views/brands/legals/view'))
const LegalsEdit = lazy(() => import('views/brands/legals/edit'))

const SubscriptionsList = lazy(() => import('views/brands/subscriptions/list'))
const SubscriptionsAdd = lazy(() => import('views/brands/subscriptions/add'))
const SubscriptionsView = lazy(() => import('views/brands/subscriptions/view'))
const SubscriptionsEdit = lazy(() => import('views/brands/subscriptions/edit'))

const BrandsRoutes = [
  {
    element: <ProductsAdd />,
    path: '/products/-/add',
    meta: {
      resource: 'products',
      action: 'add'
    }
  },
  {
    element: <ProductsView />,
    path: '/products/:id/view',
    meta: {
      resource: 'products',
      action: 'read'
    }
  },
  {
    element: <ProductsEdit />,
    path: '/products/:id/edit',
    meta: {
      resource: 'products',
      action: 'edit'
    }
  },
  {
    element: <ProductsList />,
    path: '/products',
    meta: {
      resource: 'products',
      action: 'read'
    }
  },

  {
    element: <LegalsAdd />,
    path: '/legals/-/add',
    meta: {
      resource: 'legals',
      action: 'add'
    }
  },
  {
    element: <LegalsView />,
    path: '/legals/:id/view',
    meta: {
      resource: 'legals',
      action: 'read'
    }
  },
  {
    element: <LegalsEdit />,
    path: '/legals/:id/edit',
    meta: {
      resource: 'legals',
      action: 'edit'
    }
  },
  {
    element: <LegalsList />,
    path: '/legals',
    meta: {
      resource: 'legals',
      action: 'read'
    }
  },

  {
    element: <SubscriptionsAdd />,
    path: '/subscriptions/-/add',
    meta: {
      resource: 'subscriptions',
      action: 'add'
    }
  },
  {
    element: <SubscriptionsView />,
    path: '/subscriptions/:id/view',
    meta: {
      resource: 'subscriptions',
      action: 'read'
    }
  },
  {
    element: <SubscriptionsEdit />,
    path: '/subscriptions/:id/edit',
    meta: {
      resource: 'subscriptions',
      action: 'edit'
    }
  },
  {
    element: <SubscriptionsList />,
    path: '/subscriptions',
    meta: {
      resource: 'subscriptions',
      action: 'read'
    }
  }
]

export default BrandsRoutes
