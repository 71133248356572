import { ApiUrl, getMetadata } from '../index'
import { Product, ProductBlock } from './product_pb'
import {
  GetProductRequest,
  ListProductsRequest,
  ApplyProductRequest,
  DeleteProductRequest
} from './products_pb'
import { ProductsClient } from './products_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new ProductsClient(ApiUrl, null, null);

const toProductModel = (instance) => {
  console.log(instance)

  let result = new Product()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setDomain(instance.domain)
  result.setSupportEmail(instance.supportEmail)
  result.setIsActive(instance.isActive)
  if (instance.legalNamesList) {
    result.setLegalNamesList(instance.legalNamesList)
  }
  if (instance.invoicePayeeNamesList) {
    result.setInvoicePayeeNamesList(instance.invoicePayeeNamesList)
  }
  if (instance.blocksList) {
    const blocks = []
    for (const block of instance.blocksList) {
      let blockItem = new ProductBlock()
      for (const i in block.valuesMap) {
        blockItem.getValuesMap().set(block.valuesMap[i][0], block.valuesMap[i][1])
      }
      blocks.push(blockItem)
    }
    result.setBlocksList(blocks)
  }
  result.setLogoUrl(instance.logoUrl)
  return result
}

const API = {
  listProducts: (params) => {
    const request = new ListProductsRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    return new Promise((resolve, reject) => {
      client.listProducts(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getProduct: (name) => {
    const request = new GetProductRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getProduct(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyProduct: (instance) => {
    const applying = toProductModel(instance)
    const request = new ApplyProductRequest();
    request.setProduct(applying)
    return new Promise((resolve, reject) => {
      client.applyProduct(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteProduct: (name) => {
    const request = new DeleteProductRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteProduct(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
