// ** React Imports
import { lazy } from 'react'

const CustomerCategoriesList = lazy(() => import('views/references/customer-categories/list'))
const EmailMessageTemplatesAdd = lazy(() => import('views/references/email-message-templates/add'))
const EmailMessageTemplatesView = lazy(() => import('views/references/email-message-templates/view'))
const EmailMessageTemplatesEdit = lazy(() => import('views/references/email-message-templates/edit'))
const EmailMessageTemplatesList = lazy(() => import('views/references/email-message-templates/list'))
const EmailMessageTemplateParamsList = lazy(() => import('views/references/email-message-template-params/list'))
const InvoiceTypesList = lazy(() => import('views/references/invoice-types/list'))
const PaymentTypesList = lazy(() => import('views/references/payment-types/list'))
const AffilateSystemsList = lazy(() => import('views/references/affilate-systems/list'))
const ActionStatusesList = lazy(() => import('views/references/action-statuses/list'))

const ReferencesRoutes = [
  {
    element: <CustomerCategoriesList />,
    path: '/customerCategories',
    meta: {
      resource: 'customerCategories',
      action: 'read'
    }
  },
  {
    element: <InvoiceTypesList />,
    path: '/invoiceTypes',
    meta: {
      resource: 'invoiceTypes',
      action: 'read'
    }
  },
  {
    element: <PaymentTypesList />,
    path: '/paymentTypes',
    meta: {
      resource: 'paymentTypes',
      action: 'read'
    }
  },
  {
    element: <ActionStatusesList />,
    path: '/actionStatuses',
    meta: {
      resource: 'actionStatuses',
      action: 'read'
    }
  },
  {
    element: <EmailMessageTemplatesAdd />,
    path: '/emailTemplates/-/add',
    meta: {
      resource: 'emailTemplates',
      action: 'add'
    }
  },
  {
    element: <EmailMessageTemplatesView />,
    path: '/emailTemplates/:id/view',
    meta: {
      resource: 'emailTemplates',
      action: 'read'
    }
  },
  {
    element: <EmailMessageTemplatesEdit />,
    path: '/emailTemplates/:id/edit',
    meta: {
      resource: 'emailTemplates',
      action: 'manage'
    }
  },
  {
    element: <EmailMessageTemplatesList />,
    path: '/emailTemplates',
    meta: {
      resource: 'emailTemplates',
      action: 'read'
    }
  },
  {
    element: <EmailMessageTemplateParamsList />,
    path: '/emailTemplateParams',
    meta: {
      resource: 'emailTemplateParams',
      action: 'read'
    }
  },
  {
    element: <AffilateSystemsList />,
    path: '/affilateSystems',
    meta: {
      resource: 'affilateSystems',
      action: 'read'
    }
  }
]

export default ReferencesRoutes
