// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/products'
import DocumentsAPI from 'api/documents'
import TriggerAPI from 'api/triggers'

export const listProducts = createAsyncThunk('appProducts/listProducts', async (params, { signal }) => {
  const response = await API.listProducts(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.productsList,
    totalRows: response.totalRows
  }
})

export const getProducts = createAsyncThunk('appProducts/getProducts', async (params, { signal }) => {
  const response = await API.listProducts()
  if (signal.aborted)
    return
  return response.productsList
})

export const getProduct = createAsyncThunk('appProducts/getProduct', async (name, { dispatch, getState }) => {
  const loaded = (getState().products.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appProducts.actions.setSelected(loaded))
  return await API.getProduct(name)
})

export const addProduct = createAsyncThunk('appProducts/addProduct', async (instance) => {
  const updated = await API.applyProduct(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyProduct = createAsyncThunk('appProducts/modifyProduct', async (instance, { dispatch, getState }) => {
  const updated = await API.applyProduct(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appProducts.actions.setSelected(instance))
  await dispatch(listProducts(getState().products.params))
  return instance
})

export const deleteProduct = createAsyncThunk('appProducts/deleteProduct', async (name) => {
  await API.deleteProduct(name)
  return name
})

export const listTriggers = createAsyncThunk('appCustomers/listTriggers', async (params, { signal }) => {
  const response = await TriggerAPI.listTriggers({
    ...params
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.triggersList
  }
})

export const getTrigger = createAsyncThunk('appCustomers/getTrigger', async (name, { signal }) => {
  const response = await TriggerAPI.getTrigger(name)
  if (signal.aborted)
    return
  return response
})

export const executeTrigger = createAsyncThunk('appProducts/executeTrigger', async (params) => {
  await TriggerAPI.executeTrigger(params)
})

export const getDocumentByUrl = createAsyncThunk('appProducts/getDocumentByUrl', async (url) => {
  const name = 'documents/' + encodeURIComponent(url.replace('minio://', '')).replaceAll('/', '%2F').replaceAll('%20', '+')
  return await DocumentsAPI.getDocument(name)
})

const logoLocation2type = location => location.replace('/logos', '')
const logoType2Location = type => `${type}/logos`

export const getLogos = createAsyncThunk('appProducts/getLogos', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: logoType2Location(typeName) })
  const items = []
  for (let i = 0; i < response.length; i++) {
    const logo = await DocumentsAPI.getDocument(response[i].name)
    items.push(logo)
  }
  const result = {}
  result[typeName] = items
  return result
})

export const addLogo = createAsyncThunk('appProducts/addLogo', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const addImage = createAsyncThunk('appProducts/addImage', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteLogo = createAsyncThunk('appProducts/deleteLogo', async ({ name, location }, { dispatch }) => {
  await DocumentsAPI.deleteDocument(name)
  await dispatch(getLogos(logoLocation2type(location)))
  return name
})

export const deleteImageByUrl = createAsyncThunk('appProducts/deleteImageByUrl', async ({ location, url }) => {
  const docs = await DocumentsAPI.listDocuments({ location })
  const doc = docs.filter(e => e.url === url)[0]
  if (doc) {
    await DocumentsAPI.deleteDocument(doc.name)
  }
})

export const appProducts = createSlice({
  name: 'appProducts',
  initialState: {
    data: [],
    dataAll: [],
    total: 1,
    params: {},
    logos: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.dataAll = payload
      })
      .addCase(listProducts.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getProduct.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
      .addCase(getLogos.fulfilled, (state, { payload }) => {
        state.logos = {
          ...state.logos,
          ...payload
        }
      })
      .addCase(addLogo.fulfilled, (state, { payload }) => {
        const logos = { ...state.logos }
        const key = logoLocation2type(payload.location)
        logos[key] = logos[key].concat(payload)
        state.logos = {
          ...logos
        }
      })
  }
})

export default appProducts.reducer
