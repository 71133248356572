/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/email_topics.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_date_pb = require('../date_pb.js')

var projects_erp_service_api_v1_model_email_topic_pb = require('./email_topic_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./email_topics_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.EmailTopicsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.EmailTopicsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListEmailTopicsRequest,
 *   !proto.dating.erp.api.v1.services.ListEmailTopicsResponse>}
 */
const methodDescriptor_EmailTopics_ListEmailTopics = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailTopics/ListEmailTopics',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListEmailTopicsRequest,
  proto.dating.erp.api.v1.services.ListEmailTopicsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListEmailTopicsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListEmailTopicsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListEmailTopicsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListEmailTopicsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListEmailTopicsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailTopicsClient.prototype.listEmailTopics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailTopics/ListEmailTopics',
      request,
      metadata || {},
      methodDescriptor_EmailTopics_ListEmailTopics,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListEmailTopicsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListEmailTopicsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailTopicsPromiseClient.prototype.listEmailTopics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailTopics/ListEmailTopics',
      request,
      metadata || {},
      methodDescriptor_EmailTopics_ListEmailTopics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyEmailTopicRequest,
 *   !proto.dating.erp.api.v1.model.EmailTopic>}
 */
const methodDescriptor_EmailTopics_ApplyEmailTopic = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailTopics/ApplyEmailTopic',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyEmailTopicRequest,
  projects_erp_service_api_v1_model_email_topic_pb.EmailTopic,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyEmailTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_email_topic_pb.EmailTopic.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyEmailTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.EmailTopic)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.EmailTopic>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailTopicsClient.prototype.applyEmailTopic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailTopics/ApplyEmailTopic',
      request,
      metadata || {},
      methodDescriptor_EmailTopics_ApplyEmailTopic,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyEmailTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.EmailTopic>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailTopicsPromiseClient.prototype.applyEmailTopic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailTopics/ApplyEmailTopic',
      request,
      metadata || {},
      methodDescriptor_EmailTopics_ApplyEmailTopic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteEmailTopicRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_EmailTopics_DeleteEmailTopic = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.EmailTopics/DeleteEmailTopic',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteEmailTopicRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteEmailTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteEmailTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.EmailTopicsClient.prototype.deleteEmailTopic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailTopics/DeleteEmailTopic',
      request,
      metadata || {},
      methodDescriptor_EmailTopics_DeleteEmailTopic,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteEmailTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.EmailTopicsPromiseClient.prototype.deleteEmailTopic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.EmailTopics/DeleteEmailTopic',
      request,
      metadata || {},
      methodDescriptor_EmailTopics_DeleteEmailTopic);
};


module.exports = proto.dating.erp.api.v1.services;

