// ** Icons Import
import { Archive, CreditCard, Mail, Users, Layers } from 'react-feather'

export default [
  {
    header: 'References',
    resource: 'references',
    action: 'read'
  },
  {
    id: 'customerCategories',
    title: 'Categories',
    icon: <Users size={20} />,
    navLink: '/customerCategories',
    resource: 'customerCategories',
    action: 'read'
  },
  /*{
    id: 'invoiceTypes',
    title: 'Invoice Types',
    icon: <Archive size={20} />,
    navLink: '/invoiceTypes',
    resource: 'invoiceTypes',
    action: 'read'
  },
  {
    id: 'paymentTypes',
    title: 'Payment Types',
    icon: <CreditCard size={20} />,
    navLink: '/paymentTypes',
    resource: 'paymentTypes',
    action: 'read'
  },*/
  {
    id: 'actionStatuses',
    title: 'Action Statuses',
    icon: <Layers size={20} />,
    navLink: '/actionStatuses',
    resource: 'actionStatuses',
    action: 'read'
  },
  {
    id: 'emailTemplates',
    title: 'Email Templates',
    icon: <Mail size={20} />,
    navLink: '/emailTemplates',
    resource: 'emailTemplates',
    action: 'read'
  },
  {
    id: 'emailTemplateParams',
    title: 'Email Parameters',
    icon: <Mail size={20} />,
    navLink: '/emailTemplateParams',
    resource: 'emailTemplateParams',
    action: 'read'
  },
  {
    id: 'affilateSystems',
    title: 'Affilate Systems',
    icon: <Layers size={20} />,
    navLink: '/affilateSystems',
    resource: 'affilateSystems',
    action: 'read'
  }
]
