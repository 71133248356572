// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/email_message_template_params'


function evaluateFormula(formula, context) {
  const contextKeys = Object.keys(context);

  const argNames = [...contextKeys, 'formula'];
  const argValues = [...contextKeys.map(key => context[key]), formula];

  const func = new Function(...argNames, 'return eval(formula);');
  return func(...argValues);
}

export const render = createAsyncThunk('appEmailMessageTemplateParams/render', async ({ text, context }, { dispatch }) => {
  if (!text)
    return text

  const { payload } = await dispatch(list())
  payload.forEach(p => {
    if (!text.includes(p.displayName)) {
      return
    }
    const value = evaluateFormula(p.formula, context)
    text = text.replaceAll(p.displayName, value)
  })

  return text
})

export const list = createAsyncThunk('appEmailMessageTemplateParams/list', async () => {
  return (await API.listEmailMessageTemplateParams()).emailMessageTemplateParamsList
})

export const get = createAsyncThunk('appEmailMessageTemplateParams/get', async name => {
  return await API.getEmailMessageTemplateParam(name)
})

export const add = createAsyncThunk('appEmailMessageTemplateParams/add', async (instance, { dispatch }) => {
  const updated = await API.applyEmailMessageTemplateParam(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appEmailMessageTemplateParams/modify', async (instance, { dispatch }) => {
  const updated = await API.applyEmailMessageTemplateParam(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appEmailMessageTemplateParamsSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appEmailMessageTemplateParams/del', async (name, { dispatch }) => {
  await API.deleteEmailMessageTemplateParam(name)
  await dispatch(list())
  return name
})

export const appEmailMessageTemplateParamsSlice = createSlice({
  name: 'appEmailMessageTemplateParams',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appEmailMessageTemplateParamsSlice.reducer
