class UniqueColorGenerator {
  constructor() {
    this.colorTemplate = [
      "#00FF00", "#0000FF", "#FF0000", "#00BFFF", "#800080", "#FFA500", "#FFFF00"
    ]; // Red, Green, Blue, Orange, Yellow, Light-Blue, Purple
    this.step = 30; // Step for hue shift
  }

  generateUniqueColor(existingColors) {
    let index = 0;
    let uniqueColor;
    
    while (true) {
      const baseColor = this.colorTemplate[index % this.colorTemplate.length];
      const hueShift = Math.floor(index / this.colorTemplate.length) * this.step;
      uniqueColor = this.shiftHue(baseColor, hueShift);
      
      if (!existingColors.includes(uniqueColor)) {
        return uniqueColor;
      }
      index++;
    }
  }

  shiftHue(hex, degree) {
    let rgb = this.hexToRgb(hex);
    let hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);
    hsl.h = (hsl.h + degree) % 360;
    rgb = this.hslToRgb(hsl.h, hsl.s, hsl.l);
    return this.rgbToHex(rgb.r, rgb.g, rgb.b);
  }

  hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    return { r: (bigint >> 16) & 255, g: (bigint >> 8) & 255, b: bigint & 255 };
  }

  rgbToHex(r, g, b) {
    return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase();
  }

  rgbToHsl(r, g, b) {
    r /= 255
    g /= 255
    b /= 255
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h *= 60;
    }
    return { h, s, l };
  }

  hslToRgb(h, s, l) {
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = hue2rgb(p, q, h / 360 + 1 / 3);
      g = hue2rgb(p, q, h / 360);
      b = hue2rgb(p, q, h / 360 - 1 / 3);
    }
    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
  }
}

const generator = new UniqueColorGenerator()

export default generator